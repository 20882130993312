.mint-container {
  width: 100vw;
  padding-top: 50px;
  padding-bottom: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;align-items: center;
}
.mint-section {
  justify-self: center;
  width: 60%;
  padding-top: 25px;
  padding-bottom: 50px;
  /* padding-left: 25px;
  padding-right: 25px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: black;
  border-radius: 25px;
}

.mint-section button {
  height: 25px;
  border-radius: 5px;
  border-style: solid;
  border-color: #ff004b;
  background-color: black;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
}
.mint-text {
  width: 60%;
  flex-direction: column;
  display: flex;
  margin-right: 15px;
}
.mint-text h1 {
  margin-top: 0;
  color: white;
  font-size: 100px;
  margin-bottom: -80px;
  text-align: center;
}
.mint-text h2 {
  color: #ff004b;
  font-size: 55px;
  margin-bottom: -15px;
  text-align: center;
}
.mint-text h3 {
  color: #ff004b;
  font-size: 30px;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
}
.mint-text .mint-sub-text{
  margin-top: 20px;
display: flex;
flex-direction: row;
justify-content: center;
}
.mint-text .mint-sub-text .sub-text{
  display: flex;
  flex-direction: column;
}
.mint-text h4 {
  color: white;
  font-size: 45px;
  margin-top: 0;
  margin-bottom: -30px;
  text-align: center;
}
.mint-text h5 {
color: white;
  font-size: 65px;
  margin-top: 5px;
  margin-bottom: -25px;
  text-align: center;
  margin-left: -5px;
}
.mint-text h6 {
  margin-top: 0;
  margin-bottom: 20px;
  color: white;
  font-size: 35px;
  text-align: center;
}
.button-group{
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  margin-top: 0;
  /* flex-wrap: wrap; */
  /* margin-left: -12px; */
}
.input-group{
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-flex;
}
.input-radio {
  margin-left: 0;
  margin-right: 0;
  visibility: hidden;
}
.input-radio:checked+span {
  background-color: #ff004b;
}
.input-radio-custom {
  height: 20px;
  width: 60px;
  text-align: center;
  border-radius: 5px;
  border: 2px solid #ff004b;
  color: white;
  background-color: black;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}
span .input-radio-custom{
  width: 60px;
}
.input-group label{
  cursor: pointer;
}
/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  margin-top: 30px;
  width: 90%;
  height: 25px;
  text-align: center;

  border-radius: 5px;
  border-style: solid;
  border-color: #ff004b;
  color: white;
  background: transparent;
}
input:focus{
  border: 5px solid #ff004b;
}
.mint-button {
  flex-direction: column;
  display: flex;
  justify-content:flex-start;
  align-items: center;
  margin-left:15px;
  margin-top: 0;
}
.mint-button p{
  color: white;
    font-size: 20px;
    line-height: 10px;
}
.mint-button .info{
  color: #ff004b;
  font-size: 25px;
  line-height: 10px;
  margin-bottom: 0;
}

.mint-button .alert-error {
  color: #ff004b;
  font-size: 20px;
  line-height: 10px;
  align-self: center !important;
  margin-top: 0;
}

.mint-button .alert-text {
  color: #ff004b;
  font-size: 1.5rem;
  font-weight: bold;
  align-self: center !important;
  margin-top: 20px;
}
.back {
  margin-top: 10px;
}
.view-nft{
padding-top: 20px;
}
.view-nft .nft-text{
  color: lightgray;
  font-family: 'Teko', sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  align-self: center !important;
}
.view-nft .nft-text:hover{
  color: #ff004b;
}
.mint-button img {
  cursor: pointer;
}
.disabled{
 opacity:0.5;
 pointer-events: none;
}
@media only screen and (max-width: 992px) {
  .mint-text{
    width: 90%;
  }
/* .mint-text h1 {
    font-size: 90px;
    margin-bottom: -50px;
  }
    .mint-text h2 {
      font-size: 50px;
    }
         .mint-text h3 {
           font-size: 25px;
         } */
}
@media only screen and (max-width: 768px) {
  .mint-text h1{
    font-size: 70px;
    margin-bottom: -50px;
  }
  .mint-text h2 {
font-size: 35px;
  }
  .mint-text h3{
    font-size: 20px;
  }
    .mint-button .alert-text {
      font-size: 1.3rem;
    }
}
.nft-section{
    align-self: center;
    width: 80%;
    display: flex;
    flex-direction:column;
    align-items: center;
}
.nft-section h1{
    /* color: white; */
    font-size: 40px;
    color:#ff004b;
}
.nft-section h3 {
    margin-top: 0;
    font-size: 25px;
        color: #ff004b;
}
.nft-section .nft-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-self: center;
    padding-left: 10px;
    padding-right: 10px;;
}
.nft-container .nft{
width: 300px;
padding: 10px;
cursor: pointer;
}
.nft img{
    width: 100%;
}
.nft p{
    color: white;
    text-align: center;
    font-size: 20px;
    margin-top: 0;
}
@media only screen and (max-width: 992) {
}

@media only screen and (max-width: 768px) {

.nft-container .nft {
        width: 250px;
        padding: 10px;
    }
}

@media only screen and (max-width: 600px) {
.nft-container .nft {
        width: 300px;
        padding: 10px;
    }
}
.roadmap-container {
    width: 100%;
    aspect-ratio: 11 / 3;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url('../images/animation-slide.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.roadmap-text {
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 100px;
    padding-top: 100px;
    width: 50%;
    margin: auto;
    justify-content: center;
  }
.roadmap-text h1 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 40px;
    color: #ff004b;
  }

  .roadmap-item h2 {
    font-size: 30px;
    margin-top: 15px;
    color: white;
    margin-left: 30px;
    font-weight: 100;
    letter-spacing: 1px;
  }

  .roadmap-item h1 {
    font-size: 30px;
    color: #ff004b;
    margin-left: 30px;
    width: 15%;
  }

  .roadmap-item {
    display: flex;
  }
  @media only screen and (max-width: 600px) {
    .roadmap-item h2 {
        margin-top: 15px;
      }
      .roadmap-item h1 {
        margin-left: 0px;
        width: 20%;
      }
      .roadmap-text {
        width: 70%;
      }
      .roadmap-text h2{
        margin-top: 20px;
        font-size: 20px;
      }
}

@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.App {
  text-align: center;
}
body {
  padding: 0px;
  margin: 0px;
  background-color: black;
  background-image: url("../src/images/hr-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}
h1,
h2,
h3,
h4,h5,h6 {
  font-family: "Teko", sans-serif;
}
p {
  font-family: 'Teko', sans-serif;
  font-weight: 200;
  letter-spacing: 1px;
}
.unity-canvas {
  width: 100%;
  height: 100vh;
}

a {
  text-decoration: none;
}

.NA {
  height: 80vh;
}
.NA h1 {
  padding-top: 30vh;
  text-align: center;
  font-size: 24px;
}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}
.mymodal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: black;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;

    transition: all 1s ease-in-out forwards;
}
.button-close{
    font-size: 20px;
    font-weight: bolder;
    color: #ff004b;
    background-color: black;
    float: right;
}
.display-block {
    display: block;
}

.display-none {
    display: none;
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}
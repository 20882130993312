/* This is the styling for the nav container */
.navbar {
  width: 80%;
  font-family: 'Teko', sans-serif;
  display: flex;
  flex-direction: column;
  align-self: center;

  position: absolute;
  top: 5vw;
}
/* This is the styling for the nav content */

.navbar-content {
  width: 100%;
  height: 80px;
  align-items: center;
  display: flex;
}

.navbar button {
  height: 40px;
  font-family: "Teko", sans-serif;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 25px;
  color: #ff004b;
  border-radius: 15px;
  border: none;
  background-color: black;
  cursor: pointer;
  padding-left:10px ;
  padding-right: 10px;;
}
.navbar button:hover{
  color: white;
}

/* This is the styling for the left nav container */

.left-nav {
  flex: 30%;
  display: flex;
  align-items: left;
  justify-content: left;
  padding-left: 0;
}

.left-nav img {
  height: 50px;
}
/* This is the styling for the right nav container */

.right-nav {
  flex: 70%;
  display: flex;
  justify-content: flex-end;
  padding-right: 0;
}
.right-nav a {
  color: rgba(255, 255, 255, 0.801);
  font-size: large;
  text-decoration: none;
  font-family: 'Teko', sans-serif;
  margin: 15px;
}
.right-nav a:hover {
  color: rgb(255, 255, 255);
}

.right-nav .alert-text {
  color: #ff004b;
  font-size: 15px;
  text-align: right;
  margin-top: 0;
  margin-bottom: 0;
}
.hero-container {
    height: 100vh;
    width: 80%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    height: max-content;
    flex-flow: row wrap;
    align-self: center;
}

.hero-container .hero-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}
.hero-content img{
    width: 50%;
}
.hero-image-container{
    margin-top: 30px;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
}
.hero-image-container .hero-image{
    padding: 10px;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.hero-image-container .hero-text {
    
    padding: 10px;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hero-text img{
    margin-top: 10px;
    width: 100%;
}
.hero-image img{
    padding-top: 25px;
    width: 100%;
}
.hero-image .market{
    display: inline-flex;
}
.market h3{
    color: white;
}
.market img{
    padding-left: 5px;
    padding-right: 5px;
    width:20px;
    height: 20px;
}
.market img:hover {
    transform: scale(1.1);
}
@media only screen and (max-width: 600px) {
    .hero-image-container .hero-image {
        width:80%;
    }
        .hero-image-container .hero-text {
            width: 80%;
        }
}
.header-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  height: max-content;
  flex-flow: row wrap;
}
.header-container .header-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
h1 {
  font-size: 18px;
}
.lore-text {
  margin: auto;
}
.lore-text h1 {
  margin-top: 15px;
}

.quote h1 {
  text-align: center;
  color: white;
}

.header-content button {
  width: 150px;
  flex-wrap: wrap;
  height: 45px;
  background-color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  margin: 15px;
}
.header-content button:hover {
  background-color: rgb(255, 255, 255);
}
.header-content p {
  margin-top: 5px;
  font-size: 18px;
}

.header-content img {
  /* margin-top: 50px; */
  width: 80%;
  justify-self: center;
  align-self: center;
}
.lore-text {
  width: 60vw;
  padding: 25px;
}
.options {
  padding: 15px;
}
.options p {
  color: rgb(209, 209, 209);
}
.options p:hover {
  color: white;
}

@media only screen and (max-width: 900px) {
}

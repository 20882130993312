.page-container {
  height: 100vh;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  margin-top: 50px;
  margin-bottom: 100px;
  flex-direction: column;
  overflow: hidden;

  height: max-content;

}
.page-container img {
  width: 100%;
  margin-bottom: 10px;
}
.page-container h1 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 40px;
  color: #ff004b;
}
.page-container h2 {
color: lightgray;
  font-weight: bolder;
  font-size: 40px;
}
.page-container h3{
  font-size: 35px;
  color: #ff004b;
}
.page-container h4{
  margin-top: 0;
  color: lightgray;
  font-size: 30px;
}
.page-container h5{
  margin-top: 0;
  margin-bottom: 5px;
  color: white;
  font-size: 50px;
}
.page-container p {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 5px;
  color: lightgray;
  font-size: 20px;
  font-weight: 500;
}
@media only screen and (max-width: 600px) {
.page-container h5 {
  font-size: 30px;
}
.page-container h2{
  font-size: 30px;
}
}
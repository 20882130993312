.services-content {
    align-items: center;
    justify-content: center;
    flex-direction: row;
    display: flex;
    margin: auto;
    width: 100vw;
    padding-top: 50px;
}

.services-container h1 {
    text-align: center;
    font-size: 38px;
}
.services-container h2 {
    text-align: center;
    font-size: 18px;
    color: rgb(255, 255, 255);
}
.services-container p {
    color: white;
}
.service {
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
}
.service img {
    flex-wrap: wrap;
    width: 200px;
}

@media (max-width: 800px) {
.services-content {
    flex-direction: column;
}


}